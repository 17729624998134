/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    em: "em",
    h2: "h2",
    a: "a",
    div: "div",
    hr: "hr"
  }, _provideComponents(), props.components), {PermaLink, Footnotes} = _components;
  if (!Footnotes) _missingMdxReference("Footnotes", true);
  if (!PermaLink) _missingMdxReference("PermaLink", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "A quantum neural network (QNN) is a machine learning model or algorithm that combines concepts from ", React.createElement(_components.strong, null, "quantum computing"), " and ", React.createElement(_components.strong, null, "artifical neural networks"), "."), "\n", React.createElement(_components.p, null, "Over the past decades, the term has been used to describe a variety of ideas, ranging from quantum computers ", React.createElement(_components.em, null, "emulating"), " the exact computations of neural nets, to general ", React.createElement(_components.em, null, "trainable"), " quantum circuits that bear only little resemblence with the multi-layer perceptron structure."), "\n", React.createElement(_components.h2, {
    id: "quantum-versions-of-feed-forward-neural-networks-",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#quantum-versions-of-feed-forward-neural-networks-",
    "aria-label": "quantum versions of feed forward neural networks  permalink",
    className: "hidden before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Quantum versions of feed-forward neural networks ", React.createElement(PermaLink, {
    title: "Quantum versions of feed-forward neural networks"
  })), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 630px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 44.30379746835443%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABXklEQVQoz11Ry07CQBTtN7vRRLeaGONWFwaXunflxo0Jj/AWSqnlUdpOEei00yktKvO4phQKcjdz7kzOuXfOUWBXTHAuBQDITWUAAIQQ766lE5zf5KVkHWPsslN5GqggIeOBEFLKZRSZjnPWLhZ6LfhdS0hfcwllO5bz16lVdW3bNEPfL6HJo9GdYw85jo9xHMdrxgghlNJU94gMAJo3n9LQRahVrb2MPu+GXd0wdFXtWObDoGt5i5hSGzkFrVWZu9kXlGyHIElO2sVnQ0Wmqff7w8Ws782QbXeazTqyTjvlUYBBynC5vO013pxx6oWU+8ljgnFEEUJfln3dLF2pdep5ASFhEHDGpRDY9yNKpUjN2Bm2QTiJL9qlxhStaCQ4d5JoQglnjPpBHVk3WqNmmT9JAhvDDt1OW/K9utdaE+If5pEdOsHnH+U6nmWr/otqD0Wqs00YcrCN+ijhjPwHm034h5nJ/mwAAAAASUVORK5CYII='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Quantum Neural Network\"\n        title=\"Quantum Neural Network\"\n        src=\"/static/22b272db610a352a289d1e73d66751a6/f058b/qnn_1.png\"\n        srcset=\"/static/22b272db610a352a289d1e73d66751a6/c26ae/qnn_1.png 158w,\n/static/22b272db610a352a289d1e73d66751a6/6bdcf/qnn_1.png 315w,\n/static/22b272db610a352a289d1e73d66751a6/f058b/qnn_1.png 630w,\n/static/22b272db610a352a289d1e73d66751a6/40601/qnn_1.png 945w,\n/static/22b272db610a352a289d1e73d66751a6/1628f/qnn_1.png 1232w\"\n        sizes=\"(max-width: 630px) 100vw, 630px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://arxiv.org/abs/1408.7005"
  }, "Already in the 1990s"), " quantum physicists have tried to come up with \"quantum versions\" of recurrent and feed-forward neural networks. The models were attempts to translate the modular structure as well as the nonlinear activation functions of neural networks into the language of quantum algorithms. However, one could argue that chains of linear and nonlinear computations are rather \"unnatural\" for quantum computers ", React.createElement(_components.a, {
    href: "#footnote-1"
  }, "[1]"), "."), "\n", React.createElement(_components.p, null, "More recent research has tackled this problem, suggesting special measurement schemes or modifications of the neural nets that make them more amenable to quantum computing, but the advantage of these models for machine learning is still not conclusively established."), "\n", React.createElement(_components.h2, {
    id: "quantum-versions-of-boltzmann-machines-",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#quantum-versions-of-boltzmann-machines-",
    "aria-label": "quantum versions of boltzmann machines  permalink",
    className: "hidden before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Quantum versions of Boltzmann machines ", React.createElement(PermaLink, {
    title: "Quantum versions of Boltzmann machines"
  })), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Boltzmann_machine"
  }, "Boltzmann machines"), ", which are probabilistic graphical models that can be understood as stochastic recurrent neural networks, play an important role in the quantum machine learning literature. For example, ", React.createElement(_components.a, {
    href: "https://mdenil.com/static/papers/2011-mdenil-quantum_deep_learning-nips_workshop.pdf"
  }, "it was suggested"), " to use samples from a quantum computer to train classical Boltzmann machines, or to interpret spins as physical units of a ", React.createElement(_components.a, {
    href: "https://arxiv.org/abs/1601.02036"
  }, "\"quantum\" Boltzmann machine model"), "."), "\n", React.createElement(_components.h2, {
    id: "variational-circuits--",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#variational-circuits--",
    "aria-label": "variational circuits   permalink",
    className: "hidden before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Variational circuits  ", React.createElement(PermaLink, {
    title: "Variational circuits"
  })), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 630px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 52.53164556962025%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABQklEQVQoz6VSuU7DQBDND1PTgyAIBRANNQ0NjWlAHGUoECjBJpB4A+vYie0cIrF3197s7vhAVg5FKAXHa2Y0mjdvrlL+D5RmJsuyPM+JFFmaYRI0xqMiuEjK0tT8HDkkWA3OySyKXM+bMnaADA2bJ8jYatYEYyEtIDm3fG8TG8f4NeHTpdKcnKSpAkgBzizz0e+e2qj88ZJKlQnJhQjjaELIYedNc61cwfe2Y877vh+QsNKqaxY6em/sIP3BtS97+MJCG/r909CnQYGl5opykkilQIhzu631cNVzqr3OlWeXndZdF+8jHdFiWkhgPTkMQzkVt11c67tFbwquPWfXaVJCQMpiKoAEYM22lVL9wYCOJ5W2sWfWCSGCRTdWexs9e8Mhj+KQEEopY2yN8hKEx4TzmS8B2ML/0Z3/+CTzEr8kfwGirXHzfrwUTgAAAABJRU5ErkJggg=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Quantum Neural Network\"\n        title=\"Quantum Neural Network\"\n        src=\"/static/c354f473104d5831252ac24f64ebc683/f058b/qnn_2.png\"\n        srcset=\"/static/c354f473104d5831252ac24f64ebc683/c26ae/qnn_2.png 158w,\n/static/c354f473104d5831252ac24f64ebc683/6bdcf/qnn_2.png 315w,\n/static/c354f473104d5831252ac24f64ebc683/f058b/qnn_2.png 630w,\n/static/c354f473104d5831252ac24f64ebc683/40601/qnn_2.png 945w,\n/static/c354f473104d5831252ac24f64ebc683/78612/qnn_2.png 1260w,\n/static/c354f473104d5831252ac24f64ebc683/ad00e/qnn_2.png 1366w\"\n        sizes=\"(max-width: 630px) 100vw, 630px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Increasingly, the term \"quantum neural network\" is used to ", React.createElement(_components.a, {
    href: "https://arxiv.org/abs/1802.06002"
  }, "refer to variational or parametrized quantum circuits"), ". While mathematically rather different from the inner workings of neural networks, the analogy highlights the \"modular\" nature of quantum gates in a circuit, as well as the wide use of tricks from training neural networks used in the optimization of quantum algorithms."), "\n", React.createElement(Footnotes, {
    footnotes: [{
      text: ` This is not necessarily true for photonic quantum computers, which allow for very natural implementations of neural nets (see for example [Killoran et al. (2018)](https://arxiv.org/abs/1806.06871) and [Steinbrecher et al. (2018)](https://arxiv.org/abs/1808.10047)).`,
      id: 'footnote-1'
    }]
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
